import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn } from "@/auth/utils";

export const All = "all";
export const Admin = "Admin";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        store.dispatch("app/setLoading", false);
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            component: () => import("@/views/app"),
            meta: () => ({
                roles: [All]
            })
        },
        {
            path: "/admin",
            components: {
                default: () => import("@/views/app/admin.vue"),
                'bread-actions': () => import("@/views/app/export-exel.vue")
            },
            meta: () => ({
                roles: [Admin],
                layout: 'vertical',
                breadcrumb: []
            })
        },
        {
            path: "/send",
            component: () => import("@/views/app/send-message"),
            meta: () => ({
                roles: [Admin],
                layout: 'vertical'
            })
        },
        {
            path: "/settings",
            component: () => import("@/views/app/admin-settings.vue"),
            meta: () => ({
                roles: [Admin],
                layout: 'vertical'
            })
        },
        {
            path: "/login",
            component: () => import("@/views/Login.vue"),
            meta: () => ({
                roles: [All]
            })
        },
        {
            path: "/server-error",
            name: "server error",
            component: () => import("@/views/error/server-error.vue"),
            meta: () => ({
                pageTitle: "conection-error",
                roles: [All],
            }),
        },
        {
            path: "/error-404",
            name: "error 404",
            meta: () => ({
                pageTitle: "not-found",
                roles: [All],
            }),
            component: () => import("@/views/error/error-404.vue"),
        },
        {
            path: "*",
            redirect: "/error-404",
        },
    ],
});

export default router;

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next("/login");

        // If logged in => not authorized
        return next("/access-denied");
    }

    return next();
});
