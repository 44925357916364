import jwtDefaultConfig from "./jwtDefaultConfig"
import store from "@/store"
import jwtDecode from "jwt-decode"
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import { BToast } from "bootstrap-vue"


export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null;

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig };

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false;

    // For Refreshing Token
    subscribers = [];

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns;
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                store.dispatch('app/setLoading', true)
                
                // Get token from localStorage
                 var accessToken;
                 if (router.currentRoute.path.includes("admin")) {
                     accessToken = this.getToken();
                 }
                 else {
                     accessToken = this.getWebsiteToken();
                 }

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(response => {
                store.dispatch('app/setLoading', false)
                if (response && response.status === 204 && (response.config.url.toLowerCase() == this.jwtConfig.loginEndpoint.toLowerCase() || response.config.url.toLowerCase() == "User/Login".toLowerCase())) {
                    const bToast = new BToast()
                    bToast.$bvToast.toast(`اسم المستخدم او كلمة المرور خطأ!`, {
                        title: "خطأ تسجيل الدخول",
                        variant: "danger",
                        toaster: "b-toaster-bottom-left",
                        solid: true,
                        autoHideDelay: 2000,
                        appendToast: true,
                    });
                }
                return response
            }, error => {
                store.dispatch('app/setLoading', false)
                // const { config, response: { status } } = error
                const { response } = error; // config,
                // const originalRequest = config;

                if (response && response.status === 401) {
                    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

                    // Remove userData from localStorage
                    localStorage.removeItem('userData')
                    if(router.currentRoute.path.includes("admin"))
                    {
                        
                    // Redirect to login page
                    router.push('/login')
                    }
                    else{
                        console.log("Signout")
                        router.push('/auth-signin')
                        
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback =>
            callback(accessToken)
        );
    }

    addSubscriber(callback) {
        this.subscribers.push(callback);
    }

    getToken() {
        if(localStorage.getItem(this.jwtConfig.storageTokenKeyName))
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
    }
    getWebsiteToken() {
        return localStorage.getItem(this.jwtConfig.websiteStorageTokenKeyName);
    }
    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
    }

    setWebsiteToken(value) {
        localStorage.setItem(this.jwtConfig.websiteStorageTokenKeyName, value);
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
    }

    signin(...args) {
        return this.axiosIns.post(this.jwtConfig.websiteLoginEndpoint, ...args);
    }

    refreshToken() {
        if(this.getToken()) {
            const decodedToken = jwtDecode(this.getToken())
            return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
                refreshToken: this.getRefreshToken(),
                id: +decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
            });
        }
    }
}
