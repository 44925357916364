import api from "@/EK-core/api";
import { nullGuid } from "@/EK-core/util/global";
import axiosIns from "@/libs/axios";

export default {
    state: {
        usersList: [],
        filterDto: { keywordAddress: "" },
        userDto: {
            id: nullGuid,
            name: "",
            phoneNumber: "",
            added: false,
            areaId: null,
            area: ""
        },
        msg: {
            value: ""
        },
        usersAddresses: [],
        areas: [],
        areaDto: {
            name: ""
        }
    },
    getters: {
        filteredUsers(state) {
            return state.usersList.filter(el =>
                el.areaId == state.filterDto.keywordAddress || !state.filterDto.keywordAddress
            );
        }
    },
    mutations: {
        Get_User(state, data) {
            state.usersList = data;
        },
        Add_User(state, address) {
            Object.assign(state.userDto, {
                id: nullGuid,
                name: "",
                phoneNumber: "",
                added: false,
                areaId: null,
                area: ""
            });
            if (!state.usersAddresses.length) {
                state.usersAddresses = [];
            }
            if (!state.usersAddresses.includes(address)) {
                state.usersAddresses.push(address);
            }
        },
        Delete_User(state, payload) {
            payload.forEach(id => {
                state.usersList.splice(
                    state.usersList.findIndex(u => u.id == id),
                    1
                );
            });
        },
        Add_To_Whats_App(state, payload) {
            payload.forEach(id => {
                state.usersList[
                    state.usersList.findIndex(u => u.id == id)
                ].added = true;
            });
        },
        Reset_Message(state) {
            state.msg.value = "";
        },
        GET_ADDRESSES(state, payload) {
            state.usersAddresses = payload;
        },
        RESET_FORM(state) {
            Object.assign(state.userDto, {
                id: nullGuid,
                name: "",
                phoneNumber: "",
                address: "",
                added: false
            });
        },
        Set_Areas(state, payload) {
            state.areas = payload;
        },
        Set_Area_Dto(state, payload) {
            if (payload) {
                state.areaDto = payload;
            } else {
                state.areaDto = { name: "" };
            }
        },
        Add_Area(state, payload) {
            state.areas.unshift(payload);
        },
        Delete_Area(state, id) {
            state.areas = state.areas.filter(el => el.id != id);
        },
        Delete_Areas(state, ids) {
            ids.forEach(id => {
                state.areas = state.areas.filter(el => el.id != id);
            });
        },
        Update_Area(state, payload) {
            let idx = state.areas.findIndex(el => el.id == payload.id);
            Object.assign(state.areas[idx], payload);
        }
    },
    actions: {
        getUser({ commit }, payload) {
            api.get(
                `User/Get?startDate=${new Date(payload.start)
                    .toLocaleDateString()
                    .replace(/\//g, "-")}&endDate=${new Date(payload.end)
                    .toLocaleDateString()
                    .replace(/\//g, "-")}`,
                ({ data }) => {
                    commit("Get_User", data);
                }
            );
        },
        async addUser(ctx, payload) {
            return await axiosIns.post("User/Add", payload);
        },
        deleteUser({ commit }, payload) {
            api.delete(
                "User/Delete?id=" + payload,
                ({ data }) => {
                    if (data) {
                        commit("Delete_User", [payload]);
                    }
                },
                {
                    confirm: "هل انت متأكد من حذف المستخدم",
                    success: "تمت العملية بنجاح",
                    error: "فشلت عملية الحذف"
                }
            );
        },
        deleteUsers({ commit }, payload) {
            api.delete(
                "User/DeleteRange",
                ({ data }) => {
                    if (data) commit("Delete_User", payload);
                },
                {
                    confirm: "هل انت متأكد من حذف المستخدمين المحددين",
                    success: "تمت العملية بنجاح",
                    error: "فشلت عملية الحذف"
                },
                payload
            );
        },
        addToWhatsApp({ commit }, payload) {
            api.put(
                "User/AddToWhatsApp",
                payload,
                ({ data }) => {
                    if (data) {
                        commit("Add_To_Whats_App", payload);
                    }
                },
                {
                    success: "تمت عملية الإضافة بنجاح",
                    error: "فشلت عملية الإضافة"
                }
            );
        },
        sendMessage({ commit }, msg) {
            api.get("User/SendToAllUsers?msg=" + msg, () => {
                commit("Reset_Message");
            });
        },
        getAddresses({ commit }) {
            api.get("User/GetAreas", ({ data }) => {
                commit("GET_ADDRESSES", data);
            });
        },
        getAreas({ commit }) {
            api.get("User/GetAreas", ({ data }) => {
                commit("Set_Areas", data);
            });
        },
        addArea({ commit }, payload) {
            api.post(
                "User/AddArea",
                payload,
                ({ data }) => commit("Add_Area", data.result),
                {
                    success: "تمت إضافة المنطقة ",
                    error: "فشلت الإضافة .. يرجى إعادة المحاولة"
                }
            );
        },
        deleteArea({ commit }, id) {
            api.delete(
                `User/DeleteArea?id=${id}`,
                res => {
                    if (res.status == 200) {
                        commit("Delete_Area", id);
                    }
                },
                {
                    confirm: "هل تريد بالتأكيد حذف المنطقة؟ ",
                    success: "تم حذف المنطقة ",
                    error: "فشل الحذف..حاول مجددا"
                }
            );
        },
        deleteAreas({ commit }, payload) {
            api.delete(
                "User/DeleteRangeAreas",
                ({ data }) => {
                    if (data) commit("Delete_Areas", payload);
                },
                {
                    confirm: "هل تريد بالتأكيد حذف المناطق المحددة ؟ ",
                    success: "تم حذف المناطق ",
                    error: "فشل حذف المناطق .. حاول مجددا"
                },
                payload
            );
        },
        updateArea({ commit }, payload) {
            api.post(
                "User/UpdateArea",
                payload,
                ({ data }) => {
                    commit("Update_Area", data.result);
                },
                {
                    success: "تم تعديل المنطقة ",
                    error: "فشل التعديل .. يرجى إعادة المحاولة"
                }
            );
        }
    }
};
