import store from '@/store'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
export const getLang = () => {
    if(localStorage.getItem('lang') == null) return 'ar'
    else return localStorage.getItem('lang')
}
export const setLang = (l) => {
    console.log(l)
    store.dispatch('app/setLang', l == 'ar' ? 1 : 2);
    localStorage.setItem('lang', l)
}

export const getDashLang = () => {
    if (localStorage.getItem('DashLang') == null) return 'ar';
    else return localStorage.getItem('DashLang');
}
export const setDashLang = (l) => {
    store.dispatch('app/setDashLang',l =='ar' ? 1 : 2);
    localStorage.setItem('DashLang', l)
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
